import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { setUserPin, setUserPuk, setShowPopup } from '../actions/localStates'

class PinPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {
          pin: "",
          confirmPin: "",
          puk: "",
          confirmPuk: "",

          showPin: false,
          showConfirmPin: false,
          showPuk: false,
          showConfirmPuk: false,

          confirmedPinNotEqual: false,
          confirmedPukNotEqual: false,

          pinIsEmpty: false,
          errorMessagePin: "",

          pukIsEmpty: false,
          errorMessagePuk: "",

          errorMessageForConfirmPin: "",
          errorMessageForConfirmPuk: ""
      }

      this.handlePinInput = this.handlePinInput.bind(this);
      this.handleConfirmPinInput = this.handleConfirmPinInput.bind(this);

      this.handlePukInput = this.handlePukInput.bind(this);
      this.handleConfirmPukInput = this.handleConfirmPukInput.bind(this);

      this.setPin = this.setPin.bind(this);
      this.closePinPopup = this.closePinPopup.bind(this);

      this.handlePinKeyUp = this.handlePinKeyUp.bind(this, 'pinInput');
  }

  componentDidMount() {
      document.addEventListener('keyup', this.handlePinKeyUp);
  }

  componentWillUnmount() {
      document.removeEventListener('keyup', this.handlePinKeyUp);
  }

  handlePinKeyUp(refName, e) {
      if (e.keyCode === 13 && refName === "pinInput") {
          document.getElementById("createToken").click();
          document.activeElement.blur();
      }
  }

  handlePinInput (e) {
    var _this = this;
    var value = e.target.value
    this.setState({pin: e.target.value})
    e.target.value.length > 0 && e.target.value.length < 8
      ? this.setState({errorMessagePin: "pinMinLength"}, () => {
        if (_this.state.confirmPin.length > 0) {
          _this.state.confirmPin === value
          ? _this.setState({confirmedPinNotEqual: false}, () => {
            _this.setState({errorMessageForConfirmPin: ""})
            })
          : _this.setState({confirmedPinNotEqual: true}, () => {
            _this.setState({errorMessageForConfirmPin: "pinNotEqual"})
            })
        }
      })
      : this.setState({pinIsEmpty: false}, () => {
        this.setState({errorMessagePin: ""}, () => {
          if (_this.state.confirmPin.length > 0) {
            _this.state.confirmPin === value
            ? _this.setState({confirmedPinNotEqual: false}, () => {
              _this.setState({errorMessageForConfirmPin: ""})
              })
            : _this.setState({confirmedPinNotEqual: true}, () => {
              _this.setState({errorMessageForConfirmPin: "pinNotEqual"})
              })
          }
      })
    })
  }

  handleConfirmPinInput (e) {
    this.setState({confirmPin: e.target.value})
    this.state.pin === e.target.value
    ? this.setState({confirmedPinNotEqual: false}, () => {
      this.setState({errorMessageForConfirmPin: ""})
      })
    : this.setState({confirmedPinNotEqual: true}, () => {
      this.setState({errorMessageForConfirmPin: "pinNotEqual"})
      })
  }

  handlePukInput (e) {
    var _this = this;
    var value = e.target.value
    this.setState({puk: e.target.value})
    e.target.value.length > 0 && e.target.value.length < 4
      ? this.setState({errorMessagePuk: "pukMinLength"}, () => {
        if (_this.state.confirmPuk.length > 0) {
          _this.state.confirmPuk === value
          ? _this.setState({confirmedPukNotEqual: false}, () => {
            _this.setState({errorMessageForConfirmPuk: ""})
            })
          : _this.setState({confirmedPukNotEqual: true}, () => {
            _this.setState({errorMessageForConfirmPuk: "pukNotEqual"})
            })
        }
      })
      : this.setState({pukIsEmpty: false}, () => {
        this.setState({errorMessagePuk: ""}, () => {
          if (_this.state.confirmPuk.length > 0) {
            _this.state.confirmPuk === value
            ? _this.setState({confirmedPukNotEqual: false}, () => {
              _this.setState({errorMessageForConfirmPuk: ""})
              })
            : _this.setState({confirmedPukNotEqual: true}, () => {
              _this.setState({errorMessageForConfirmPuk: "pukNotEqual"})
              })
          }
      })
    })
  }

  handleConfirmPukInput (e) {
    this.setState({confirmPuk: e.target.value})
    this.state.puk === e.target.value
    ? this.setState({confirmedPukNotEqual: false}, () => {
      this.setState({errorMessageForConfirmPuk: ""})
      })
    : this.setState({confirmedPukNotEqual: true}, () => {
      this.setState({errorMessageForConfirmPuk: "pukNotEqual"})
      })
  }

  setPin () {
      this.props.actions.setUserPin(this.state.pin);
      this.props.actions.setUserPuk(this.state.puk);
      this.props.closeClientPopup(this.state.pin, this.state.puk);
  }

  closePinPopup () {
    this.props.actions.setShowPopup(false);
  }

  hoverPin() {
        this.setState({showPin: true})
  }

  outPin() {
      this.setState({showPin: false})
  }

  hoverConfirmPin() {
        this.setState({showConfirmPin: true})
  }

  outConfirmPin() {
      this.setState({showConfirmPin: false})
  }

  hoverPuk() {
        this.setState({showPuk: true})
  }

  outPuk() {
      this.setState({showPuk: false})
  }

  hoverConfirmPuk() {
        this.setState({showConfirmPuk: true})
  }

  outConfirmPuk() {
      this.setState({showConfirmPuk: false})
  }

  render() {
    let isEnabled = (this.state.pin.length > 7 && this.state.puk.length > 3 && this.state.confirmPin.length > 7 && this.state.confirmPuk.length > 3) && (!this.state.confirmedPinNotEqual && !this.state.confirmedPukNotEqual)
    ? true
    : false

      return (
          <div className='popup'>
              <div className={"popup_inner_user_pin"}>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <h4 style={{paddingLeft: "15px", paddingTop: "30px"}}>{i18next.t("createTokenLabel")}</h4>
                      <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("pinInput")}&nbsp;
                              <i className="fa fa-info-circle"></i>
                              <div className="tooltip">{i18next.t("requirementsPin")}</div>
                          </label>
                          <div className="col-12 input-group">
                              <input className={(this.state.errorMessagePin.length > 0) ? "form-control is-invalid" : "form-control"} value={this.state.pin} type={this.state.showPin ? "input" : "password"} onChange={this.handlePinInput} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverPin.bind(this)} onMouseOut={this.outPin.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>
                          {
                              this.state.errorMessagePin.length > 0
                              ? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessagePin)}</span>
                              : null
                          }
                      </div>
                      <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("confirmPin")}&nbsp;
                              <i className="fa fa-info-circle"></i>
                              <div className="tooltip">{i18next.t("requirementsPin")}</div>
                          </label>
                          <div className="col-12 input-group">
                              <input className={this.state.confirmedPinNotEqual ? "form-control is-invalid" : "form-control"} value={this.state.confirmPin} type={this.state.showConfirmPin ? "input" : "password"} onChange={this.handleConfirmPinInput} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverConfirmPin.bind(this)} onMouseOut={this.outConfirmPin.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>
                          {
                              this.state.errorMessageForConfirmPin.length > 0
                              ? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessageForConfirmPin)}</span>
                              : null
                          }
                      </div>
                      <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("puk")}&nbsp;
                              <i className="fa fa-info-circle"></i>
                              <div className="tooltip">{i18next.t("requirementsPuk")}</div>
                          </label>
                          <div className="col-12 input-group">
                              <input className={(this.state.errorMessagePuk.length > 0) ? "form-control is-invalid" : "form-control"} value={this.state.puk} type={this.state.showPuk ? "input" : "password"} onChange={this.handlePukInput} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverPuk.bind(this)} onMouseOut={this.outPuk.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>
                          {
                              this.state.errorMessagePuk.length > 0
                              ? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessagePuk)}</span>
                              : null
                          }
                      </div>
                      <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("confirmPuk")}&nbsp;
                              <i className="fa fa-info-circle"></i>
                              <div className="tooltip">{i18next.t("requirementsPuk")}</div>
                          </label>
                          <div className="col-12 input-group">
                              <input className={this.state.confirmedPukNotEqual ? "form-control is-invalid" : "form-control"} value={this.state.confirmPuk} type={this.state.showConfirmPuk ? "input" : "password"} onChange={this.handleConfirmPukInput} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverConfirmPuk.bind(this)} onMouseOut={this.outConfirmPuk.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>
                          {
                              this.state.errorMessageForConfirmPuk.length > 0
                              ? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessageForConfirmPuk)}</span>
                              : null
                          }
                      </div>
                      <div style={{marginRight: "15px", paddingTop: "10px"}}>
                          <button className="btn btn-outline-info" style={{float: "right",marginLeft: "5px", marginBottom: "30px"}} onClick={this.closePinPopup}>{i18next.t("cancelLabel")}</button>
                          <button className="btn btn-info" id="createToken" style={{float: "right", marginLeft: "5px", marginBottom: "30px"}} disabled={!isEnabled} onClick={this.setPin}>{i18next.t("createTokenLabel")}</button>
                      </div>
                  </div>
              </div>
          </div>
      );
    }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        selectedClient: state.clientReducer,
        login: state.loginReducer,
        userReducer: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      setUserPin, setUserPuk, setShowPopup
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PinPopup);