import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { setShowChangePinPopup, setOldPin, setNewPin } from '../actions/localStates'

class ChangePinPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {
          confirmNewPin: "",

          confirmedPinNotEqual: false,

          pinIsEmpty: false,
          errorMessagePin: "",

          errorMessageForConfirmPin: "",
      }

      this.handleOldPinInput = this.handleOldPinInput.bind(this);
      this.handleNewPinInput = this.handleNewPinInput.bind(this);
      this.handleConfirmNewPinInput = this.handleConfirmNewPinInput.bind(this);

      this.setPin = this.setPin.bind(this);
      this.closeChangePinPopup = this.closeChangePinPopup.bind(this);

      this.handleOldPinKeyUp = this.handleOldPinKeyUp.bind(this, 'oldPinInput');
      this.handleNewPinKeyUp = this.handleNewPinKeyUp.bind(this, 'newPinInput');
  }

  componentDidMount() {
      var element = document.getElementById("oldPin");
      element.focus();
      document.addEventListener('keyup', this.handleOldPinKeyUp);
      document.addEventListener('keyup', this.handleNewPinKeyUp);
  }

  componentWillUnmount() {
      document.removeEventListener('keyup', this.handleOldPinKeyUp);
      document.removeEventListener('keyup', this.handleNewPinKeyUp);
  }

  handleOldPinKeyUp(refName, e) {
      if (e.keyCode === 13 && refName === "oldPinInput") {
          document.getElementById("submitPin").click();
          document.activeElement.blur();
      }
  }

  handleNewPinKeyUp(refName, e) {
      if (e.keyCode === 13 && refName === "newPinInput") {
          document.getElementById("submitPin").click();
          document.activeElement.blur();
      }
  }

  handleOldPinInput (e) {
    // console.log(e.target.value)
    this.props.actions.setOldPin(e.target.value)
  }

  handleNewPinInput (e) {
    var _this = this;
    var value = e.target.value
    this.props.actions.setNewPin(e.target.value)
    e.target.value.length > 0 && e.target.value.length < 8
      ? this.setState({errorMessagePin: "pinMinLength"}, () => {
        if (_this.state.confirmNewPin.length > 0) {
          _this.state.confirmNewPin === value
          ? _this.setState({confirmedPinNotEqual: false}, () => {
            _this.setState({errorMessageForConfirmPin: ""})
            })
          : _this.setState({confirmedPinNotEqual: true}, () => {
            _this.setState({errorMessageForConfirmPin: "pinNotEqual"})
            })
        }
      })
      : this.setState({pinIsEmpty: false}, () => {
        this.setState({errorMessagePin: ""}, () => {
          if (_this.state.confirmNewPin.length > 0) {
            _this.state.confirmNewPin === value
            ? _this.setState({confirmedPinNotEqual: false}, () => {
              _this.setState({errorMessageForConfirmPin: ""})
              })
            : _this.setState({confirmedPinNotEqual: true}, () => {
              _this.setState({errorMessageForConfirmPin: "pinNotEqual"})
              })
          }
      })
    })
  }

  handleConfirmNewPinInput (e) {
    this.setState({"confirmNewPin": e.target.value})
    this.props.userReducer.newPin === e.target.value
    ? this.setState({confirmedPinNotEqual: false}, () => {
      this.setState({errorMessageForConfirmPin: ""})
      })
    : this.setState({confirmedPinNotEqual: true}, () => {
      this.setState({errorMessageForConfirmPin: "pinNotEqual"})
      })
  }

  setPin () {
    this.props.closeChangePinPopup(this.props.userReducer.tempId);
  }

  closeChangePinPopup () {
    this.props.actions.setOldPin("")
    this.props.actions.setNewPin("")
    this.props.actions.setShowChangePinPopup(false);
  }

  hoverOldPin() {
        this.setState({showOldPin: true})
  }

  outOldPin() {
      this.setState({showOldPin: false})
  }

  hoverNewPin() {
        this.setState({showNewPin: true})
  }

  outNewPin() {
      this.setState({showNewPin: false})
  }

  hoverConfirmNewPin() {
        this.setState({showConfirmNewPin: true})
  }

  outConfirmNewPin() {
      this.setState({showConfirmNewPin: false})
  }

  render() {
    let isEnabled = this.props.userReducer.oldPin.length >= 8 && this.props.userReducer.newPin.length >= 8 && (!this.state.confirmedPinNotEqual && !this.state.confirmedPukNotEqual)
    ? true
    : false

      return (
          <div className='popup'>
              <div className={"popup_inner_user_token_pin"}>
                  <div className="col-12">
                    <h4 style={{paddingLeft: "15px", paddingTop: "30px"}}>{i18next.t("changePinTitle")}</h4>
                      <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingTop: "0px", paddingBottom: "0px"}}>{i18next.t("oldPinLabel")}</label>
                          <div className="col-12 input-group">
                              <input id="oldPin" className="form-control" value={this.props.userReducer.oldPin} type={this.state.showOldPin ? "input" : "password"} onChange={this.handleOldPinInput} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverOldPin.bind(this)} onMouseOut={this.outOldPin.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>
                          
                      </div>

                      <div style={{paddingTop: "10px"}}>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingTop: "0px", paddingBottom: "0px"}}>{i18next.t("newPinLabel")}&nbsp;
                              <i className="fa fa-info-circle"></i>
                              <div className="tooltip">{i18next.t("requirementsPin")}</div>
                          </label>
                          <div className="col-12 input-group">
                              <input id="newPin" className="form-control" value={this.props.userReducer.newPin} type={this.state.showNewPin ? "input" : "password"} onChange={this.handleNewPinInput} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverNewPin.bind(this)} onMouseOut={this.outNewPin.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>
                          {
                              this.state.errorMessagePin.length > 0
                              ? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessagePin)}</span>
                              : null
                          }
                      </div>

                      <div style={{paddingTop: "10px"}}>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingTop: "0px", paddingBottom: "0px"}}>{i18next.t("confirmPin")}&nbsp;
                              <i className="fa fa-info-circle"></i>
                              <div className="tooltip">{i18next.t("requirementsPin")}</div>
                          </label>
                          <div className="col-12 input-group">
                              <input id="newPin" className="form-control" value={this.state.confirmNewPin} type={this.state.showConfirmNewPin ? "input" : "password"} onChange={this.handleConfirmNewPinInput} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverConfirmNewPin.bind(this)} onMouseOut={this.outConfirmNewPin.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>
                          {
                              this.state.errorMessageForConfirmPin.length > 0
                              ? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessageForConfirmPin)}</span>
                              : null
                          }
                      </div>

                      <div style={{float: "right", marginRight: "15px", paddingTop: "10px"}}>
                          <button className="btn btn-info" id="submitPin" style={{marginLeft: "5px", marginBottom: "30px"}} disabled={!isEnabled} onClick={this.setPin}>{i18next.t("confirmChangePinLabel")}</button>
                          <button className="btn btn-outline-info" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.closeChangePinPopup}>{i18next.t("cancelLabel")}</button>
                      </div>
                  </div>
              </div>
          </div>
      );
    }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        selectedClient: state.clientReducer,
        login: state.loginReducer,
        userReducer: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      setShowChangePinPopup, setOldPin,
      setNewPin
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePinPopup);