const baseState = {
    COMMON_NAME: "",
	COUNTRY_NAME: "",
	GIVEN_NAME: "",
	LOCALITY_NAME: "",
	STATE_OR_PROVINCE_NAME: "",
	STREET_ADDRESS: "",
	SURNAME: "",
	EXTERNAL_USER_ID: "",
	TITLE: "",
	ORGANIZATION_NAME: "",
	ORGANIZATIONAL_UNIT_NAME: "",
	POSTAL_CODE: "",
	MAIL: "",
	DRFOU: "",
	EDRPOU: "",
	PASSPORT: "",
	UNZR: "",
	TELEPHONE_NUMBER: "",
	SERIAL: "",
	CHALLENGE_PASSWORD: "",
	ALLOWED_REGISTRAR_CIP_ID: "",
  	ALLOWED_CERTIFICATE_PROFILE_ID: ""
}

const keyGenReducer = (state = baseState, { type, payload }) => {

    switch (type) {

    	case "SET_ALL_KEY_GEN_PARAMS": {
			return {
				...state,
				COMMON_NAME: (payload !== null && payload.COMMON_NAME !== null && payload.COMMON_NAME !== undefined) ? payload.COMMON_NAME : "",
				COUNTRY_NAME: (payload !== null && payload.COUNTRY_NAME !== null && payload.COUNTRY_NAME !== undefined) ? payload.COUNTRY_NAME : "",
				GIVEN_NAME: (payload !== null && payload.GIVEN_NAME !== null && payload.GIVEN_NAME !== undefined) ? payload.GIVEN_NAME : "",
				LOCALITY_NAME: (payload !== null && payload.LOCALITY_NAME !== null && payload.LOCALITY_NAME !== undefined) ? payload.LOCALITY_NAME : "",
				STATE_OR_PROVINCE_NAME: (payload !== null && payload.STATE_OR_PROVINCE_NAME !== null && payload.STATE_OR_PROVINCE_NAME !== undefined) ? payload.STATE_OR_PROVINCE_NAME : "",
				STREET_ADDRESS: (payload !== null && payload.STREET_ADDRESS !== null && payload.STREET_ADDRESS !== undefined) ? payload.STREET_ADDRESS : "",
				SURNAME: (payload !== null && payload.SURNAME !== null && payload.SURNAME !== undefined) ? payload.SURNAME : "",
				EXTERNAL_USER_ID: (payload !== null && payload.EXTERNAL_USER_ID !== null && payload.EXTERNAL_USER_ID !== undefined) ? payload.EXTERNAL_USER_ID : "",
				TITLE: (payload !== null && payload.TITLE !== null && payload.TITLE !== undefined) ? payload.TITLE : "",
				ORGANIZATION_NAME: (payload !== null && payload.ORGANIZATION_NAME !== null && payload.ORGANIZATION_NAME !== undefined) ? payload.ORGANIZATION_NAME : "",
				ORGANIZATIONAL_UNIT_NAME: (payload !== null && payload.ORGANIZATIONAL_UNIT_NAME !== null && payload.ORGANIZATIONAL_UNIT_NAME !== undefined) ? payload.ORGANIZATIONAL_UNIT_NAME : "",
				POSTAL_CODE: (payload !== null && payload.POSTAL_CODE !== null && payload.POSTAL_CODE !== undefined) ? payload.POSTAL_CODE : "",
				MAIL: (payload !== null && payload.MAIL !== null && payload.MAIL !== undefined) ? payload.MAIL : "",
				DRFOU: (payload !== null && payload.DRFOU !== null && payload.DRFOU !== undefined) ? payload.DRFOU : "",
				EDRPOU: (payload !== null && payload.EDRPOU !== null && payload.EDRPOU !== undefined) ? payload.EDRPOU : "",
				PASSPORT: (payload !== null && payload.PASSPORT !== null && payload.PASSPORT !== undefined) ? payload.PASSPORT : "",
				UNZR: (payload !== null && payload.UNZR !== null && payload.UNZR !== undefined) ? payload.UNZR : "",
				TELEPHONE_NUMBER: (payload && payload.TELEPHONE_NUMBER !== null && payload.TELEPHONE_NUMBER !== undefined) ? payload.TELEPHONE_NUMBER : "",
				SERIAL: (payload !== null && payload.SERIAL !== null && payload.SERIAL !== undefined) ? payload.SERIAL : "",
				CHALLENGE_PASSWORD: (payload && payload.CHALLENGE_PASSWORD !== null && payload.CHALLENGE_PASSWORD !== undefined) ? payload.CHALLENGE_PASSWORD : "",
				ALLOWED_REGISTRAR_CIP_ID: (payload && payload.ALLOWED_REGISTRAR_CIP_ID !== null && payload.ALLOWED_REGISTRAR_CIP_ID !== undefined) ? payload.ALLOWED_REGISTRAR_CIP_ID : "",
			  	ALLOWED_CERTIFICATE_PROFILE_ID: (payload && payload.ALLOWED_CERTIFICATE_PROFILE_ID !== null && payload.ALLOWED_CERTIFICATE_PROFILE_ID !== undefined) ? payload.ALLOWED_CERTIFICATE_PROFILE_ID : ""
			}
		}

		case "CLEAR_ALL_KEY_GEN_PARAMS": {
			return {
				...state,
				COMMON_NAME: "",
				COUNTRY_NAME: "",
				GIVEN_NAME: "",
				LOCALITY_NAME: "",
				STATE_OR_PROVINCE_NAME: "",
				STREET_ADDRESS: "",
				SURNAME: "",
				EXTERNAL_USER_ID: "",
				TITLE: "",
				ORGANIZATION_NAME: "",
				ORGANIZATIONAL_UNIT_NAME: "",
				POSTAL_CODE: "",
				MAIL: "",
				DRFOU: "",
				EDRPOU: "",
				PASSPORT: "",
				UNZR: "",
				TELEPHONE_NUMBER: "",
				SERIAL: "",
				CHALLENGE_PASSWORD: "",
				ALLOWED_REGISTRAR_CIP_ID: "",
				ALLOWED_CERTIFICATE_PROFILE_ID: ""
			}
		}

        default:
            return state;
    }
}

export default keyGenReducer